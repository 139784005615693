import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CustomAppBar from './components/Layout/AppBar';
import Sidebar from './components/Layout/Sidebar';
import MainContent from './components/Layout/MainContent';
import Home from './pages/Home';
import Books from './pages/Books';
import Login from './pages/Login';
import Register from './pages/Register';
import { UserProvider, useUser } from './UserContext';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme();

function AppContent() {
  const { user } = useUser();
  const location = useLocation();

  // 检查当前路径是否为登录或注册页面
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
        <CustomAppBar sx={{ height: '64px' }} />
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
          {!isAuthPage && <Sidebar />}
          <MainContent>
            <Routes>
              <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
              <Route path="/register" element={user ? <Navigate to="/" /> : <Register />} />
              <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
              <Route path="/books/:courseName" element={user ? <Books /> : <Navigate to="/login" />} />
            </Routes>
          </MainContent>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function App() {
  return (
    <UserProvider>
      <Router>
        <AppContent />
      </Router>
    </UserProvider>
  );
}

export default App;
