import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBooks, fetchCardsByCourse, fetchUserTestHistory } from '../services/api';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  CircularProgress, 
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
} from '@mui/material';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import CardTestDialog from '../components/CardTestDialog';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function Books() {
  const { courseName } = useParams();
  const [books, setBooks] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [isCardTestDialogOpen, setIsCardTestDialogOpen] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const { user } = useUser();
  const navigate = useNavigate();
  const [cardMemoryStatus, setCardMemoryStatus] = useState({});
  const [selectedCards, setSelectedCards] = useState([]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [booksData, cardsData, testHistory] = await Promise.all([
          fetchBooks(courseName),
          fetchCardsByCourse(courseName),
          fetchUserTestHistory(user.id, courseName)
        ]);
        const booksWithCardCount = booksData.books.map(book => ({
          ...book,
          totalCards: book.lessons.reduce((sum, lesson) => sum + lesson.cards.length, 0)
        }));
        setBooks(booksWithCardCount);
        setAllCards(cardsData);

        // 处理测试历史
        const memoryStatus = {};
        testHistory.forEach(card => {
          memoryStatus[card.cardName] = card.memoryStatus;
        });
        setCardMemoryStatus(memoryStatus);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [courseName, user.id]);

  const uniqueSelectedCards = useMemo(() => {
    const selectedCardNames = selectedLessons.flatMap(lesson => lesson.cards);
    return allCards.filter(card => selectedCardNames.includes(card.name));
  }, [selectedLessons, allCards]);

  const handleBookDoubleClick = (book) => {
    const newLessons = book.lessons.map(lesson => ({
      bookName: book.name,
      lessonName: lesson.name,
      cards: lesson.cards
    }));
    setSelectedLessons(prevLessons => {
      const combinedLessons = [...prevLessons, ...newLessons];
      return Array.from(new Set(combinedLessons.map(JSON.stringify))).map(JSON.parse);
    });
  };

  const handleLessonDoubleClick = (book, lesson) => {
    setSelectedLessons(prevLessons => {
      const newLesson = { bookName: book.name, lessonName: lesson.name, cards: lesson.cards };
      const combinedLessons = [...prevLessons, newLesson];
      return Array.from(new Set(combinedLessons.map(JSON.stringify))).map(JSON.parse);
    });
  };

  const handleSelectedLessonDoubleClick = (lessonToRemove) => {
    setSelectedLessons(prevLessons => 
      prevLessons.filter(lesson => 
        !(lesson.bookName === lessonToRemove.bookName && lesson.lessonName === lessonToRemove.lessonName)
      )
    );
  };

  const handleClearSelectedLessons = () => {
    setSelectedLessons([]);
  };

  const handleStartTest = () => {
    const shuffledCards = shuffleArray([...uniqueSelectedCards]);
    setSelectedCards(shuffledCards);
    setIsCardTestDialogOpen(true);
  };

  const handleTestComplete = (results) => {
    console.log(results);
    // TODO: 在这里处理测试结果，例如更新 API 或本地状态
    setIsCardTestDialogOpen(false);
  };

  const handleCloseTestDialog = () => {
    setIsCardTestDialogOpen(false);
  };

  const handleNextCard = () => {
    setCurrentCardIndex(prevIndex => Math.min(prevIndex + 1, uniqueSelectedCards.length - 1));
  };

  const handlePrevCard = () => {
    setCurrentCardIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const renderCardDescription = (description) => {
    if (typeof description === 'string') {
      return <Typography>{description}</Typography>;
    }
    
    return (
      <Box>
        {Object.entries(description).map(([key, value]) => (
          <Box key={key} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold">{key}:</Typography>
            {typeof value === 'object' ? (
              <Paper elevation={1} sx={{ p: 1, backgroundColor: '#f5f5f5' }}>
                <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {JSON.stringify(value, null, 2)}
                </pre>
              </Paper>
            ) : (
              <Typography>{value}</Typography>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const getColorByMemoryStatus = (status) => {
    if (!status) return 'black'; // 如果没有状态（即没有测试记录），返回黑色
    switch (status) {
      case 'PERMANENT_MEMORY': return 'grey';
      case 'LONG_TERM_MEMORY': return 'darkblue';
      case 'SHORT_TERM_MEMORY': return 'darkgreen';
      case 'DIFFICULT_TO_REMEMBER': return 'darkred';
      case 'LEARNING': return 'orange';
      case 'NOT_STUDIED': return 'black';
      default: return 'black'; // 对于任何未知状态，也返回黑色
    }
  };

  // 首先，让我们创建一个函数来获取记忆状态的中文描述
  const getMemoryStatusDescription = (status) => {
    switch (status) {
      case 'PERMANENT_MEMORY': return '永久记忆';
      case 'LONG_TERM_MEMORY': return '长期记忆';
      case 'SHORT_TERM_MEMORY': return '短期记忆';
      case 'DIFFICULT_TO_REMEMBER': return '记忆困难';
      case 'LEARNING': return '学习中';
      case 'NOT_STUDIED': return '未学习';
      default: return '未知状态';
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ 
      display: 'grid',
      gridTemplateRows: '45% 10% 45%',
      gridTemplateColumns: '1fr',
      height: '100%',
      overflow: 'hidden'
    }}>
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: '30% 70%',
        overflow: 'hidden'
      }}>
        <Box sx={{ overflow: 'auto', borderRight: '1px solid #ccc' }}>
          <Typography variant="h6" sx={{ p: 1, bgcolor: '#f5f5f5', position: 'sticky', top: 0, zIndex: 1 }}>Books</Typography>
          <List dense disablePadding>
            {books.map((book, index) => (
              <ListItem 
                button 
                key={index} 
                onClick={() => setSelectedBook(book)}
                onDoubleClick={() => handleBookDoubleClick(book)}
                selected={selectedBook === book}
                sx={{ borderBottom: '1px solid #eee' }}
              >
                <ListItemText 
                  primary={
                    <Typography component="span">
                      {book.name}
                      <Typography component="span" color="textSecondary" sx={{ ml: 1 }}>
                        ({book.totalCards} cards)
                      </Typography>
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ overflow: 'auto' }}>
          <Typography variant="h6" sx={{ p: 1, bgcolor: '#f5f5f5', position: 'sticky', top: 0, zIndex: 1 }}>Lessons</Typography>
          {selectedBook ? (
            <List dense disablePadding>
              {selectedBook.lessons.map((lesson, index) => (
                <ListItem 
                  key={index}
                  onDoubleClick={() => handleLessonDoubleClick(selectedBook, lesson)}
                  sx={{ borderBottom: '1px solid #eee' }}
                >
                  <ListItemText 
                    primary={
                      <Typography component="span">
                        {lesson.name} 
                        <Typography component="span" color="textSecondary" sx={{ ml: 1 }}>
                          ({lesson.cards.map(card => (
                            <span 
                              key={card} 
                              style={{ color: getColorByMemoryStatus(cardMemoryStatus[card]) }}
                              title={getMemoryStatusDescription(cardMemoryStatus[card])}
                            >
                              {card}
                            </span>
                          )).reduce((prev, curr) => [prev, ', ', curr])}
                          )
                        </Typography>
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={{ p: 1 }}>请选择一本书以查看课程</Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc'
      }}>
        <Button variant="contained" color="primary" onClick={handleStartTest}>
          开始测试
        </Button>
        <Button variant="contained" onClick={handleClearSelectedLessons}>
          清空
        </Button>
      </Box>
      <CardTestDialog 
        open={isCardTestDialogOpen} 
        onClose={() => setIsCardTestDialogOpen(false)}
        cards={selectedCards}
        onTestComplete={handleTestComplete}
      />

      <Box sx={{ overflow: 'auto' }}>
        <Typography variant="h6" sx={{ p: 1, bgcolor: '#f5f5f5', position: 'sticky', top: 0, zIndex: 1 }}>
          已选择的课程 (共 {uniqueSelectedCards.length} 张卡片)
        </Typography>
        <List dense disablePadding>
          {selectedLessons.map((lesson, index) => (
            <ListItem 
              key={index}
              onDoubleClick={() => handleSelectedLessonDoubleClick(lesson)}
              sx={{ borderBottom: '1px solid #eee' }}
            >
              <ListItemText 
                primary={
                  <Typography component="span">
                    {`${lesson.bookName} - ${lesson.lessonName}`}
                    <Typography component="span" color="textSecondary" sx={{ ml: 1 }}>
                      ({lesson.cards.map(card => (
                        <span 
                          key={card} 
                          style={{ color: getColorByMemoryStatus(cardMemoryStatus[card]) }}
                          title={getMemoryStatusDescription(cardMemoryStatus[card])}
                        >
                          {card}
                        </span>
                      )).reduce((prev, curr) => [prev, ', ', curr])}
                      )
                    </Typography>
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default Books;