import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogContent,
  DialogTitle, 
  Button,
  Typography,
  Box,
  Divider
} from '@mui/material';

function CardTestDialog({ open, onClose, cards, onTestComplete }) {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [testResults, setTestResults] = useState([]);

  // 重置状态
  useEffect(() => {
    if (open) {
      setCurrentCardIndex(0);
      setTestResults([]);
    }
  }, [open]);

  const handleNextCard = (result) => {
    setTestResults([...testResults, { ...cards[currentCardIndex], result }]);
    if (currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else {
      handleEndTest();
    }
  };

  const handleEndTest = () => {
    onTestComplete(testResults);
    onClose();
  };

  if (!open || cards.length === 0) return null;

  const currentCard = cards[currentCardIndex];

  return (
    <Dialog 
      open={open} 
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth={false}
      disableEscapeKeyDown
      PaperProps={{
        style: {
          width: '70vw',
          height: '70vh',
          maxWidth: 'none',
          maxHeight: 'none',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle>测试卡片 ({currentCardIndex + 1}/{cards.length})</DialogTitle>
      <DialogContent style={{ display: 'flex', flexGrow: 1, padding: 0 }}>
        {/* 左侧：卡片信息展示区 */}
        <Box sx={{ 
          width: '70%', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2
        }}>
          <Typography variant="h4" align="center" gutterBottom>
            {currentCard.name}
          </Typography>
          <Typography variant="body1" align="center">
            {JSON.stringify(currentCard.description, null, 2)}
          </Typography>
        </Box>

        <Divider orientation="vertical" flexItem />

        {/* 右侧：功能区 */}
        <Box sx={{ 
          width: '30%', 
          display: 'flex', 
          flexDirection: 'column', 
          padding: 2
        }}>
          <Button 
            onClick={handleEndTest} 
            color="error" 
            fullWidth 
            variant="contained"
            sx={{ mb: 2 }}
          >
            结束测试
          </Button>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button 
              onClick={() => handleNextCard('unknown')} 
              sx={{ 
                bgcolor: 'error.main', 
                color: 'white', 
                '&:hover': { bgcolor: 'error.dark' },
                flexBasis: '48%'
              }} 
              variant="contained"
            >
              不认识
            </Button>
            <Button 
              onClick={() => handleNextCard('known')} 
              sx={{ 
                bgcolor: 'success.main', 
                color: 'white', 
                '&:hover': { bgcolor: 'success.dark' },
                flexBasis: '48%'
              }} 
              variant="contained"
            >
              认识
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CardTestDialog;