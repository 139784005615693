import React from 'react';
import Typography from '@mui/material/Typography';

function Home() {
  return (
    <Typography paragraph>
      欢迎来到学习卡片应用！这里是主要内容区域，未来将展示看板数据。
    </Typography>
  );
}

export default Home;
