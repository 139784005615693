import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

function MainContent({ children }) {
  return (
    <Box 
      component="main" 
      sx={{ 
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar /> {/* 这个空的 Toolbar 会创建一个与 AppBar 等高的空间 */}
      <Box sx={{
        flexGrow: 1,
        p: 3, // 给内容添加内边距
        overflow: 'auto', // 允许内容滚动
      }}>
        {children}
      </Box>
    </Box>
  );
}

export default MainContent;
