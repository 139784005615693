const API_BASE_URL = 'https://learn-by-card.leonyxb.workers.dev/api';

export async function fetchCourses() {
  const response = await fetch(`${API_BASE_URL}/courses`);
  if (!response.ok) {
    throw new Error('网络响应出错');
  }
  const data = await response.json();
  if (!data.success) {
    throw new Error('获取课程失败');
  }
  return data.courses;
}

export async function fetchBooks(courseName) {
  const response = await fetch(`${API_BASE_URL}/books?course=${encodeURIComponent(courseName)}`);
  if (!response.ok) {
    throw new Error('网络响应出错');
  }
  const data = await response.json();
  if (!data.success) {
    throw new Error('获取书籍失败');
  }
  return data;
}

export const fetchCardsByCourse = async (courseName) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cards?course=${encodeURIComponent(courseName)}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    if (data.success) {
      return data.cards;
    } else {
      throw new Error(data.error || 'Failed to fetch cards');
    }
  } catch (error) {
    console.error('Error fetching cards:', error);
    throw error;
  }
};

export async function login(email, password) {
  try {
    const response = await fetch(`${API_BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (data.success) {
      return data.user;
    } else {
      throw new Error(data.error || 'Login failed');
    }
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
}

export async function register(username, email, password) {
  try {
    const response = await fetch(`${API_BASE_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, email, password }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (data.success) {
      return data.user;
    } else {
      throw new Error(data.error || 'Registration failed');
    }
  } catch (error) {
    console.error('Error during registration:', error);
    throw error;
  }
}

export async function fetchUserTestHistory(userId, course) {
  const response = await fetch(`${API_BASE_URL}/user-test-history?userId=${userId}&course=${encodeURIComponent(course)}`);
  if (!response.ok) {
    throw new Error('网络响应出错');
  }
  const data = await response.json();
  if (!data.success) {
    throw new Error('获取用户测试历史失败');
  }
  return data.testHistory;
}

// 可以在这里添加更多的 API 调用函数
