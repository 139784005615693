import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CourseList from '../CourseList';

const drawerWidth = '16.67%'; // 1/6 of the width

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column', height: '100%' }}>
        {isHomePage ? (
          <CourseList />
        ) : (
          <Button onClick={() => navigate('/')} sx={{ m: 2 }}>
            返回课程选择
          </Button>
        )}
      </Box>
    </Drawer>
  );
}

export default Sidebar;
